import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import Spinner from '@/components/atoms/Spinner'
import {
  GroundWrapper,
  ConfirmationWrapper,
  OrderProccessTextWrapper,
  HousesWrapper,
  MyHouseWrapper,
  LorryWrapper,
  NewHouseWrapper,
  OrderCompleteWrapper,
  StyledButton,
  OrderStatusText,
} from './Confirmation.Styles'
import MyHouse from '@/public/images/MyHouse.svg'
import NewAddressIcon from '@/public/images/NewAddressIcon.svg'
import ArrowRight from '@/public/images/ArrowRight.svg'
import Lorry from '@/public/images/Lorry.svg'
import Ground from '@/public/images/Ground.svg'
import Magic from '@/public/images/Magic.svg'
import Button from '@/components/atoms/Button'
import Flex from '@/components/atoms/Flex'
import { useAddonContext } from '@/common/context/addon/AddonProvider'
import { useFormAddressContext } from '@/common/context/addressForm/AddressFormProvider'
import { useMoveDateContext } from '@/common/context/moveDate/MoveDateProvider'
import { useSupplierIdContext } from '@/common/context/supplierId/SupplierIdProvider'
import { useContactInfoContext } from '@/common/context/contactInfo/ContactInfoProvider'
import createOrder from '@/helpers/createOrder'
import { YES } from '@/constants/addressFormConstants'
import ActivityEnum from '@/common/enums/ActivityEnum'
import { CLEANING, MOVING } from '@/constants/common'
import { useCurrentStepContext } from '@/common/context/steps/CurrentStepProvider'
import { HANDYMAN, MOVECLEAN, MOVEHELP, PACKING, STORAGE } from '@/constants/AddonConstants'
import useApi from '@/helpers/api'
import { useRouter } from 'next/router'
import va from '@vercel/analytics'

export interface ConfirmationProps {
  type: string
  isQuotation: boolean
  setError: (data: ErrorObject) => void
}
const START = 'start'
const MIDDLE = 'middle'
const END = 'end'

const Confirmation = ({ type, setError, isQuotation }: ConfirmationProps) => {
  const { t } = useTranslation(['summary'])
  const api = useApi()
  const router = useRouter()
  const { newAddressInfo, currentAddressInfo } = useFormAddressContext()
  const { addons } = useCurrentStepContext()
  const { movingDate } = useMoveDateContext()
  const { contactInfo } = useContactInfoContext()
  const { supplierId } = useSupplierIdContext()
  const [lorryPosition, setLorryPosition] = useState<typeof START | typeof MIDDLE | typeof END>('start')
  const [orderCompleted, setOrderCompleted] = useState<boolean>(false)
  const [startPageUrl, setStartPageUrl] = useState<string>('')
  const header = isQuotation ? 'CONFIRMATION.quotationComplete' : 'CONFIRMATION.orderComplete'
  const description = isQuotation ? 'CONFIRMATION.quotationCompleteDescription' : 'CONFIRMATION.orderCompleteDescription'

  useEffect(() => {
    const createOrderRepsonse = async () => {
      await createOrder({
        type: type === ActivityEnum.MOVEHELP && addons.includes(MOVECLEAN) ? 'movehelpCombined' : type,
        supplierId,
        isQuotation,
        customerComment: currentAddressInfo.limitedAccessibilityCustomerNotes,
        meta: {
          wantsHandyman: addons.includes(HANDYMAN),
          wantsStorage: addons.includes(STORAGE),
          //Backend throws an error without wantsDisposal field, should remove once backend is updated
          wantsDisposal: false,
          wantsPackaging: addons.includes(PACKING),
          heavyLifting: currentAddressInfo.heavyLifting === YES ? true : false,
        },
        fromResidence: {
          street: `${currentAddressInfo.street} ${currentAddressInfo.streetNumber}`,
          city: currentAddressInfo.city,
          zip: currentAddressInfo.zip,
          elevator: currentAddressInfo.elevatorSize,
          size: currentAddressInfo.residenceSize,
          floor: currentAddressInfo.floor,
          sizeOfAdditionalSpace: currentAddressInfo.sizeOfAdditionalSpace,
          residenceType: currentAddressInfo.residenceType,
          isLimitedAccess: currentAddressInfo.limitedAccessibility === YES ? true : false,
        },
        toResidence: {
          street: `${newAddressInfo.street} ${newAddressInfo.streetNumber}`,
          city: newAddressInfo.city,
          zip: newAddressInfo.zip,
          elevator: newAddressInfo.elevatorSize,
          size: newAddressInfo.residenceSize,
          floor: newAddressInfo.floor,
          sizeOfAdditionalSpace: newAddressInfo.sizeOfAdditionalSpace,
          residenceType: newAddressInfo.residenceType,
          isLimitedAccess: newAddressInfo.limitedAccessibility === YES ? true : false,
        },
        contact: {
          pno: contactInfo.socialSecurityNumber,
          firstName: contactInfo.firstName,
          lastName: contactInfo.lastName,
          phone: contactInfo.phoneNumber,
          email: contactInfo.email,
        },
        ...(type === ActivityEnum.MOVEHELP && { movehelp: { deliveryDate: format(movingDate, 'yyyy-MM-dd'), service: MOVING } }),
        ...(type === ActivityEnum.MOVEHELP && addons.includes(MOVECLEAN) && { moveclean: { deliveryDate: format(movingDate, 'yyyy-MM-dd'), service: CLEANING } }),
        ...(type === ActivityEnum.MOVECLEAN && { moveclean: { deliveryDate: format(movingDate, 'yyyy-MM-dd'), service: CLEANING } }),
        ...(type === ActivityEnum.MOVECLEAN && addons.includes(MOVEHELP) && { movehelp: { deliveryDate: format(movingDate, 'yyyy-MM-dd'), service: MOVING } }),
      }).then((res) => {
        if (!res?.message) {
          va.track(isQuotation ? 'QUOTATION_CREATED' : 'ORDER_CREATED', {
            productName: type === ActivityEnum.MOVEHELP && addons.includes(MOVECLEAN) ? 'movehelpCombined' : type,
          })
          setLorryPosition(END)
          setOrderCompleted(true)

          switch (isQuotation) {
            case true:
              type === ActivityEnum.MOVECLEAN ? window.parent.postMessage('bokaflytt', '*') : window.parent.postMessage('bokaflyttstad', '*')
              break
            case false:
              type === ActivityEnum.MOVECLEAN ? window.parent.postMessage('offertflytt', '*') : window.parent.postMessage('offertflyttstad', '*')
              break
            default:
              break
          }
        } else {
          setError({
            isError: true,
            title: t('errors:callYou'),
            description: t('errors:callYouDescription'),
            subTitle: t<string>('errors:MESSAGE_KEYS.ERROR_IN_ORDER_CREATE'),
          })
        }
      })
    }
    createOrderRepsonse()
  }, [supplierId])

  useEffect(() => {
    const validateOrganisation = async () => {
      const response = await api.fetch<ValidationResponse>(`/widget/auth/validate/${router.query.organizationId}`, 'GET')
      setStartPageUrl(response.redirectUrl || '')
    }
    validateOrganisation()
  }, [api, router.query.organizationId])

  return (
    <ConfirmationWrapper>
      {!orderCompleted ? (
        <Flex direction="column" justifyContent="center" alignItems="center" className="spinnerWrapper">
          <Spinner scale={2} />
          <OrderProccessTextWrapper>
            <OrderStatusText>{t(`${header}`)}</OrderStatusText>
          </OrderProccessTextWrapper>
        </Flex>
      ) : (
        <OrderCompleteWrapper>
          <div className="magicIcon">
            <Magic width={167} height={171} />
          </div>
          <OrderStatusText>{t(`${header}`)}</OrderStatusText>
          <p style={{ width: '310px' }}>{t(`${description}`)}</p>
          <StyledButton>
            <Button onClick={() => router.push(startPageUrl)} text={t('CONFIRMATION.homePage')} variant="primaryAlt" iconRight={<ArrowRight />} />
          </StyledButton>
        </OrderCompleteWrapper>
      )}
    </ConfirmationWrapper>
  )
}

export default Confirmation
